// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-confirm-submission-js": () => import("./../../../src/pages/confirm-submission.js" /* webpackChunkName: "component---src-pages-confirm-submission-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fantasy-assistant-js": () => import("./../../../src/pages/fantasy-assistant.js" /* webpackChunkName: "component---src-pages-fantasy-assistant-js" */),
  "component---src-pages-hhcms-js": () => import("./../../../src/pages/hhcms.js" /* webpackChunkName: "component---src-pages-hhcms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kisit-js": () => import("./../../../src/pages/kisit.js" /* webpackChunkName: "component---src-pages-kisit-js" */)
}

